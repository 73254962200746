html, body, main {
    height: 100%;
}

#root {
    height: 100%;
}

p {
    text-align: justify;
    text-justify: inter-word;
}

.HV-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sign-up-img-div:after {
    content: '';
    background-color: #07122E;
    opacity: 0.75;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.sign-in-img-div:after {
    content: '';
    background-color: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.button {
    display: flex;
    padding: 5px 25px;
    border-radius: 30px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
}

.button-rect {
    border-radius: 0;
}

.button:link, .button:visited {
    text-decoration: none;
    display: inline-block;
    opacity: 1;
    border-radius: '50%';
}

.button:hover, .button:active {
    opacity: 0.8;
    text-decoration: none;
    cursor: pointer;
}

.social-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: #202a43;
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.social-button:hover {
    background-color: #3490DD;
    cursor: pointer;
}

.secondary-back {
    background-color: #FC9C52;
}

.info-back {
    background-color: #E8F5FF;
}

.secondary-font {
    color: #FC9C52;
}

.main-back {
    background-color: #3490DD;
}

.main-font {
    color: #3490DD;
}

.title-font {
    color: #07122E;
}

.paragraph-font {
    color: #6B6C72;
}

.white-font {
    color: white;
}

li {
    font-weight: 'bold';
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    list-style-type: none;
    margin: 0px;
}

.nav-bar>li>div {
    font-size: 16px;
    width: 50%;
    height: 30px;
    white-space: nowrap;
}

.nav-bar>li>div:hover {
    border-bottom: 2px solid #FC9C52;
    color: #3490DD;
    cursor: pointer;
}

.nav-bar-active {
    border-bottom: 2px solid #FC9C52;
    color: #3490DD;
    font-weight: bold;
}

.footer-link {
    color: white;
}

.footer-link .arrow {
    display: none;
}

.footer-link:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.footer-link:hover .arrow {
    display: inline-block;
}

.footer-menu {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 700;
    color: white;
}

.searchbar-container {
    width: 80%;
    height: 60px;
    margin-top: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1%;
    box-shadow: 0 0px 15px 0 rgba(52, 144, 221, 0.3);
    background-color: white;
}

.subscribebar-container {
    width: 50%;
    margin: auto;
    height: 84px;
    margin-top: 70px;
    border-radius: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1%;
    box-shadow: 0 0px 15px 0 rgba(7, 18, 46, 0.2);
    background-color: white;
}

.range-select:hover {
    cursor: pointer;
}

.slider-color {
    color: red;
}

.carousel-item5-read-more {
    color: #3490DD;
}

.carousel-item5-read-more:hover {
    color: #07122E;
}

.slick-prev:before, .slick-next:before {
    content: '';
}

.slick-prev, .slick-next {
    border-radius: 20px;
    background-color: #E4F3FF;
    color: #3490DD;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
}

.slick-prev:hover, .slick-next:hover {
    opacity: 1;
    border-radius: 20px;
    background-color: #3490DD;
    color: white;
}

.slick-dots li button:before {
    font-size: 10px;
    color: #FC9C52;
}

.slick-dots .slick-active button:before {
    color: #3490DD !important;
}

/* .slick-list {
    margin-bottom: 60px;
} */

.slick-dots {
    position: static;
}

.slick-center>div>div>div>.react-player {
    transform: scale(1.5) !important;
    transition: all .3s ease;
}

.slick-center>div>div span:nth-child(2) {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #07122E !important;
    padding-top: 25% !important;
    width: 140%;
}

.slick-center>div>div span:nth-child(3) {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #6B6C72 !important;
}

.player-wrapper {
    position: relative;
    width: 100%;
    padding-top: 90%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.sign-edit-container {
    height: 52px;
    max-width: 500px;
    margin-top: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0 15px 22px;
    box-shadow: 0 0px 5px 0 rgba(52, 144, 221, 0.3);
    background-color: white;
}

.sign-auth-form {
    padding: 120px 0 0 110px;
    min-width: calc(100% - 960px);
}

.pointer:hover {
    cursor: pointer;
}

.social-sign-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    width: 140px;
    height: 3rem;
    border-radius: 6px;
    margin-right: 10px;
}

.social-sign-button:hover {
    text-decoration: none;
    opacity: 0.8;
}

.checkbox {
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

@media screen and (max-width: 768px) {
    .sign-img-container {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .sign-auth-form {
        padding: 50px 0 0 20px;
    }
}

.input-range__slider {
    background: white !important;
    border: 6px solid #FC9C52 !important;
    height: 1.5rem !important;
    top: -3px !important;
    width: 1.5rem !important;
}

.input-range__track {
    background: #3490DD !important;
}

.input-range__track--active {
    background: #FC9C52 !important;
}

.ingredient-element-item {
    display: flex;
    align-items: center;
    background-color: #EBF1FF;
    border-radius: 6px;
    padding: 10px;
    margin: 5px 0;
}

.ingredient-element-item:hover {
    background-color: #FFF5EB;
}

.instruction-step-item {
    margin-bottom: 15px;
    border-radius: 6px;
    padding: 10px;
}

.instruction-step-item:hover {
    background-color: #FFF5EB;
}

.recipe-action-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #F5F8FE;
    border-radius: 6px;
}

.recipe-action-item-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #3490DD;
    border-radius: 6px;
}

.recipe-action-item:hover {
    background-color: #FFF5EB;
    cursor: pointer;
}

.recipe-action-item-active:hover {
    background-color: #FFF5EB;
    cursor: pointer;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
    border: none;
    outline: none;
    padding-left: 20px;
    font-size: 16px;
    color: #07122E;
    width: calc(100% - 50px);
}

.custom-sign-input {
    border: none;
    outline: none;
    padding-left: 20px;
    font-size: 16px;
    color: #07122E;
    width: calc(100% - 50px);
}

.custom-input {
    border: none;
    outline: none;
    padding-left: 10px;
    font-size: 16px;
    color: #07122E;
    width: 100%;
}

.preferences-wizard {
    background-color: white;
    max-width: 500px;
    margin: auto;
    padding: 30px;
    border-radius: 6px;
}

.edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    padding: 10px;
    border-radius: 50%;
}

.edit-button:hover {
    opacity: 0.8;
    cursor: pointer;
}

.section-text-describe {
    max-width: 445px;
    font-size: 16px;
    color: #6B6C72;
    text-align: justify;
    text-justify: inter-word;
}

.calendar-day {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid #EBECEE;
}

.calendar-day-text {
    font-size: 12px;
    color: #6B6C72;
    font-weight: 500;
}

.calendar-date-text {
    font-size: 16px;
    color: #07122E;
    font-weight: 600;
}

.calendar-meal-time {
    position: relative;
    height: 130px;
    border-bottom: 1px solid #EBECEE;
}

.calendar-meal-time .edit {
    position: relative;
    float: right;
    padding-right: 5px;
    display: none;
}

.calendar-meal-time .calorie {
    position: absolute;
    bottom: 10px;
    padding-right: 5px;
    color: #3490DD;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    left: 10px;
}

.calendar-meal-time:hover {
    opacity: 0.8;
    cursor: default;
}

.calendar-meal-time:hover .edit {
    display: inline-block;
}

.calendar-meal-time>p {
    text-align: center;
}

.calendar-today-date-text {
    font-size: 24px;
    font-weight: 600;
    color: #3490DD;
}

.calendar-today-day-text {
    font-size: 16px;
    color: #6B6C72;
}

.profile-subject {
    font-size: 14px;
    color: #6B6C72;
    margin-bottom: 5px;
}

.profile-value {
    font-size: 16px;
    color: #07122E;
}